var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1200px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.test.wellname)+": "+_vm._s(_vm.test.description))]),_c('v-card-text',[_c('listitems',{key:_vm.cor_key2,attrs:{"title":'Waivers List',"list":_vm.waivers_list,"headers":_vm.event_headers,"showstd":false,"showedit":false,"master":false,"add":_vm.edit &&
          _vm.waivers_list.findIndex(
            function (x) { return x.operator_id == this$1.$store.state.me.id && !x.accept; }
          ) < 0,"del":true,"btn_update":true,"del_disable":!_vm.edit ||
          !(
            !_vm.waiver.is_accepted &&
            _vm.waiver.operator_id == this.$store.state.me.id &&
            _vm.plan.isActif == 1
          ),"update_desa":!_vm.edit ||
          !(
            !_vm.waiver.is_accepted &&
            _vm.waiver.operator_id == this.$store.state.me.id &&
            _vm.plan.isActif == 1
          )},on:{"open":_vm.WaiverOpen,"btn_update_click":_vm.WaiverUpdate,"delete":_vm.WaiverOpen,"rowselect":_vm.WaiverSelect,"col_btn1_click":_vm.WaiverSet_State}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"blue darken-1"},on:{"click":function($event){return _vm.close_dialog()}}},[_vm._v(" Close ")])],1)],1),_c('waiverform',{key:_vm.k_waiver,attrs:{"dialog":_vm.waiver_dialog,"waiver":_vm.waiver,"edit":true},on:{"WaiverClose":_vm.WaiverClose}}),_c('confirmdialog',{ref:"confirm"}),_c('confirmdialog',{ref:"accept"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }